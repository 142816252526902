import * as React from 'react'
import { css } from '@emotion/core'

import Event from './Event'

const headerStyle = (theme) => css`
	${theme.typography.header};
	margin: 0;
`

const hrStyle = (theme) => css`
	border-color: ${theme.colors.blue};
	border-bottom: 0;
	border-top: 1px solid;
	margin-top: 40px;
`

const container = css`
	max-width: 100%;
`

const translations = {
	'Jest i będzie:': 'Current and future:',
	'Było:': 'Past:',
	'Edukacja': 'Education',
	'Realizacje i interwencje': 'Realizations and interventions',
	'Archiwa i badania': 'Archives and research'
}

const getText = (phrase, language) => {
	if (language === 'en') {
		return translations[phrase]
	}
	return phrase
}

const mapEventData = (event, language) => {
	const parsed = {
		...event,
	}
	if (language === 'en') {
		parsed.title = event.en_title
		parsed.description = event.en_description
	}
	return parsed
}

const EventList = ({ events, language }) => {
	const currentEvents = events.filter((event) => new Date(event.end_date) >= new Date())
	const pastEvents = events.filter((event) => new Date(event.end_date) < new Date())
	return (
		<div css={container}>
			{currentEvents.length > 0 && (
				<>
					<h2 css={headerStyle}>{getText('Jest i będzie:', language)}</h2>
					{currentEvents.map((_event) => {
						const event = mapEventData(_event, language)
						return (
							<Event
								key={event.id}
								title={event.title}
								startDate={event.start_date}
								endDate={event.end_date}
								category={getText(event.category, language)}
								description={event.description}
								link={event.link}
								images={event.images}
							/>
						)
					})}
				</>
			)}
			{currentEvents.length > 0 && pastEvents.length > 0 && <hr css={hrStyle} />}
			{pastEvents.length > 0 && (
				<>
					<h2 css={headerStyle}>{getText('Było:', language)}</h2>
					{pastEvents.map((_event) => {
						const event = mapEventData(_event, language)
						return (
							<Event
								key={event.id}
								title={event.title}
								startDate={event.start_date}
								endDate={event.end_date}
								category={getText(event.category, language)}
								description={event.description}
								link={event.link}
								images={event.images}
							/>
						)
					})}
				</>
			)}
		</div>
	)
}

export default EventList
