import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import EventList from '../components/EventList'
import { parseEvents } from '../parsers'
import ContentGrid from '../components/ContentGrid'

const IndexPage = ({ data, pageContext }) => {
	const lang = (pageContext && pageContext.language) || 'pl'
	const events = parseEvents(data)
	const linkToDifferentLanguage = lang === 'pl' ? '/en' : '/'
	return (
		<Layout linkToDifferentLanguage={linkToDifferentLanguage} language={lang}>
			<SEO title="Wydarzenia" />
			<ContentGrid>
				<EventList events={events} language={lang} />
			</ContentGrid>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	{
		allMarkdownRemark(
			filter: { frontmatter: { layout: { eq: "event" } } }
			sort: { fields: [frontmatter___start_date], order: ASC }
		) {
			edges {
				...EventData
			}
		}
	}
`
