import * as React from 'react'
import Img from 'gatsby-image'
import { css } from '@emotion/core'
import ExternalLink from './ExternalLink'

const titleStyle = (past) => (theme) => css`
	${past ? theme.typography.big : theme.typography.header};
	${past && { fontWeight: 'normal' }};
	margin: 0;
`

const dateAndTimeStyle = (past) => (theme) => css`
	${theme.typography.big};
	color: ${past ? 'inherit' : theme.colors.red};
`

const categoryStyle = (theme) => css`
	${theme.typography.basic};
`

const descriptionStyle = (theme) => css`
	${theme.typography.big};
	margin: 0;
	margin-bottom: 20px;
	text-indent: 75px;
	p {
		margin: 0;
	}
	a {
		text-decoration: none;
		color: ${theme.colors.blue};
	}
`

const articleStyle = (theme) => css`
	color: ${theme.colors.blue};
	margin-bottom: 20px;
	word-break: break-word;
`

const linkStyle = css`
	text-decoration: none;
`

const imageStyle = css`
	margin-right: 15px;
	max-width: 100%;
`

const imagesContainer = css`
	max-width: 100%;
	display: flex;
	overflow-x: auto;

	& > * {
		flex-shrink: 0;
	}
`

const sameDay = (date1, date2) => {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	)
}

const getFormatedDate = (startDate, endDate) => {
	const startDateYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(startDate)
	const startDateMonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(startDate)
	const startDateDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(startDate)
	if (sameDay(startDate, endDate)) {
		return `${startDateDay}.${startDateMonth}.${startDateYear}`
	}
	const endDateMonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(endDate)
	const endDateDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(endDate)
	return `${startDateDay}.${startDateMonth} - ${endDateDay}.${endDateMonth}.${startDateYear}`
}

const getFormatedTime = (startDate, endDate) => {
	if (!sameDay(startDate, endDate)) {
		return ''
	}
	const time = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: false }).format(startDate)
	return `/g: ${time}`
}

const Event = ({ title, startDate, endDate, category, description, link, past, images }) => {
	const parsedStartDate = new Date(startDate)
	const parsedEndDate = new Date(endDate)
	if (!endDate) {
		return null
	}
	return (
		<article css={articleStyle}>
			<div>
				<span css={dateAndTimeStyle(past)}>
					—{getFormatedDate(parsedStartDate, parsedEndDate)}
					{getFormatedTime(parsedStartDate, parsedEndDate)}
				</span>{' '}
				<span css={categoryStyle}>[{category}]</span>
			</div>
			<ExternalLink link={link} css={linkStyle}>
				<h1 css={titleStyle(past)}>{title}</h1>
			</ExternalLink>
			<div
				css={descriptionStyle}
				dangerouslySetInnerHTML={{
					__html: description,
				}}
			></div>
			<div>
				<div css={imagesContainer}>
					{images &&
						images.filter(Boolean).map((image, index) => {
							return <Img key={index} css={imageStyle} fixed={image.childImageSharp.fixed} alt="" />
						})}
				</div>
			</div>
		</article>
	)
}

export default Event
